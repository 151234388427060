var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "lista-apolice" },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0" },
        [
          _c("breadcrumb", {
            attrs: { title: "Apólices", actualPage: "Lista de apólices" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-3 mt-4 mb-6 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2",
        },
        [
          !_vm.perfilHelpers.isInsurance()
            ? [_c("location-dropdown")]
            : _vm._e(),
          _c(
            "filtro",
            {
              ref: "filtro",
              attrs: { titleName: "Apólices", isFilter: _vm.filtro },
            },
            [
              _c(
                "div",
                { staticClass: "px-4" },
                [
                  _c(
                    "vs-row",
                    { staticClass: "pt-0" },
                    [
                      _c("h5", { staticClass: "title-group pt-0 pb-2" }, [
                        _vm._v("Dados da apólice"),
                      ]),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: {
                              size: "large",
                              label: "Número da apólice",
                            },
                            model: {
                              value: _vm.filter.policyNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "policyNumber", $$v)
                              },
                              expression: "filter.policyNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Status da a pólice"),
                          ]),
                          _c("v-select", {
                            staticClass: "w-full",
                            attrs: {
                              placeholder: "Selecionar",
                              "show-no-options": false,
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                              label: "name",
                              options: _vm.policyStatusOptionsMixin,
                              size: "large",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setPoliceStatusOption(
                                  _vm.selectedPolicyStatusOption
                                )
                              },
                            },
                            model: {
                              value: _vm.selectedPolicyStatusOption,
                              callback: function ($$v) {
                                _vm.selectedPolicyStatusOption = $$v
                              },
                              expression: "selectedPolicyStatusOption",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Data inicial"),
                          ]),
                          _c("datepicker", {
                            attrs: {
                              language: _vm.language,
                              format: "dd/MM/yyyy",
                            },
                            model: {
                              value: _vm.filter.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "startDate", $$v)
                              },
                              expression: "filter.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-6",
                          attrs: { "vs-lg": "6", "vs-sm": "12" },
                        },
                        [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Data final"),
                          ]),
                          _c("datepicker", {
                            attrs: {
                              language: _vm.language,
                              format: "dd/MM/yyyy",
                            },
                            model: {
                              value: _vm.filter.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "endDate", $$v)
                              },
                              expression: "filter.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.perfilHelpers.isInsurance()
                    ? _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { staticClass: "flex justify-between" },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  model: {
                                    value: _vm.filter.enableToBigRisk,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "enableToBigRisk",
                                        $$v
                                      )
                                    },
                                    expression: "filter.enableToBigRisk",
                                  },
                                },
                                [_vm._v("Grande risco\n            ")]
                              ),
                              _c(
                                "vs-checkbox",
                                {
                                  model: {
                                    value: _vm.filter.enableToMonitoring,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "enableToMonitoring",
                                        $$v
                                      )
                                    },
                                    expression: "filter.enableToMonitoring",
                                  },
                                },
                                [_vm._v("Monitoramento\n            ")]
                              ),
                              _c(
                                "vs-checkbox",
                                {
                                  model: {
                                    value: _vm.filter.enableToSteppin,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "enableToSteppin",
                                        $$v
                                      )
                                    },
                                    expression: "filter.enableToSteppin",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Retoma de objeto (Step-in)\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr", { staticClass: "mt-6 opacity-25" }),
                  _c(
                    "vs-row",
                    [
                      _c("h5", { staticClass: "title-group pb-2" }, [
                        _vm._v("Dados dos envolvidos"),
                      ]),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "8", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: { size: "large", label: "Nome do tomador" },
                            model: {
                              value: _vm.filter.policyHolderName,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "policyHolderName", $$v)
                              },
                              expression: "filter.policyHolderName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: {
                              size: "large",
                              label: "Documento do tomador",
                            },
                            model: {
                              value: _vm.filter.policyHolderDocument,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filter,
                                  "policyHolderDocument",
                                  $$v
                                )
                              },
                              expression: "filter.policyHolderDocument",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-row",
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "8", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: { size: "large", label: "Nome do segurado" },
                            model: {
                              value: _vm.filter.insuredName,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "insuredName", $$v)
                              },
                              expression: "filter.insuredName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-col",
                        { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                        [
                          _c("vs-input", {
                            attrs: {
                              size: "large",
                              label: "Documento do segurado",
                            },
                            model: {
                              value: _vm.filter.insuredDocument,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "insuredDocument", $$v)
                              },
                              expression: "filter.insuredDocument",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.perfilHelpers.isInsurance()
                    ? _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { attrs: { "vs-lg": "8", "vs-sm": "12" } },
                            [
                              _c("vs-input", {
                                attrs: {
                                  size: "large",
                                  label: "Nome do corretor",
                                },
                                model: {
                                  value: _vm.filter.brokerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "brokerName", $$v)
                                  },
                                  expression: "filter.brokerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                            [
                              _c("vs-input", {
                                attrs: {
                                  size: "large",
                                  label: "Documento do corretor",
                                },
                                model: {
                                  value: _vm.filter.brokerDocument,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "brokerDocument", $$v)
                                  },
                                  expression: "filter.brokerDocument",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex flex-row-reverse mt-4" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: { color: "primary", type: "filled" },
                          on: {
                            click: function ($event) {
                              return _vm.listarApolices(true)
                            },
                          },
                        },
                        [_vm._v("\n            Aplicar filtro\n          ")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "button-default",
                          attrs: { type: "filled", icon: "clear" },
                          on: {
                            click: function ($event) {
                              return _vm.limparFiltros()
                            },
                          },
                        },
                        [_vm._v("\n            Limpar filtro\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.perfilHelpers.checkPermission([
            _vm.perfilHelpers.menuFuncionalitiesEnum.SOLICITAR_ENDOSSO,
          ])
            ? _c(
                "vs-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.showBuscarApolice()
                    },
                  },
                },
                [_vm._v("Endosso")]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.apolices.length > 0
        ? _c(
            "div",
            { staticClass: "lista-apolices" },
            _vm._l(_vm.apolices, function (item, index) {
              return _c("div", { key: index, staticClass: "apolice" }, [
                _c("div", { staticClass: "topo" }, [
                  _c("div", { staticClass: "linha" }, [
                    _c("p", [
                      _vm._v("\n            Apólice:\n            "),
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.PolicyNumber ? item.PolicyNumber : "S/N"
                            )
                        ),
                      ]),
                      _c(
                        "i",
                        {
                          staticClass: "copy",
                          attrs: { role: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.copyToClipboard(
                                item.PolicyNumber ? item.PolicyNumber : "S/N"
                              )
                            },
                          },
                        },
                        [_c("onPointIcon", { attrs: { icon: "copy" } })],
                        1
                      ),
                    ]),
                    _c("p", [
                      _vm._v("\n            Proposta: "),
                      _c("b", [
                        _vm._v(" " + _vm._s((item || {}).ProposalNumber)),
                      ]),
                      item.ProposalNumber
                        ? _c(
                            "i",
                            {
                              staticClass: "copy",
                              attrs: { role: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard(
                                    item.ProposalNumber
                                  )
                                },
                              },
                            },
                            [_c("onPointIcon", { attrs: { icon: "copy" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    item.CompanyReferenceNumber
                      ? _c("p", [
                          _vm._v(
                            "\n            Ref. da Seguradora:\n            "
                          ),
                          _c("b", [
                            _vm._v(
                              " " + _vm._s((item || {}).CompanyReferenceNumber)
                            ),
                          ]),
                          item.CompanyReferenceNumber
                            ? _c(
                                "i",
                                {
                                  staticClass: "copy",
                                  attrs: { role: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyToClipboard(
                                        item.CompanyReferenceNumber
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("onPointIcon", {
                                    attrs: { icon: "copy" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "botoes" }, [
                    _c(
                      "div",
                      { staticClass: "baixar" },
                      [
                        item.ArchivePolicyId !== 0 ||
                        item.ArchiveLastEndorsementId !== 0 ||
                        (_vm.perfilHelpers.isInsurance() &&
                          item.ArchivePolicyId !== 0)
                          ? _c(
                              "el-dropdown",
                              {
                                attrs: { "split-button": "", type: "primary" },
                              },
                              [
                                _vm._v(
                                  "\n              Baixar arquivos\n              "
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    item.ArchivePolicyId !== 0
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.downloadApoliceClick(
                                                  item.RelatedProposalUniqueId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-base px-6 py-2",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Download da apólice (original)"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.ArchiveLastEndorsementId !== 0
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.downloadUltimoEndossoClick(
                                                  item.RelatedProposalUniqueId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-base px-6 py-2",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Download do endosso (mais recente)"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.perfilHelpers.isInsurance() &&
                                    item.ArchivePolicyId !== 0
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.downloadLaudoEmissaoClick(
                                                  item.OriginalProposalUniqueId
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-base px-6 py-2",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Download do laudo de emissão (original)"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.HasInstallment
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.boletoApolice(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-base px-6 py-2",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " Download do(s) boleto(s) (original)"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-dropdown",
                              {
                                attrs: {
                                  "split-button": "",
                                  type: "primary",
                                  disabled: true,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Baixar arquivos\n              "
                                ),
                                _c("el-dropdown-menu", {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "detalhar" },
                      [
                        _c(
                          "el-dropdown",
                          {
                            attrs: { "split-button": "", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.goToPolicy(item.PolicyUniqueId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              Detalhes da apólice\n\n              "
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.perfilHelpers.checkPermission([
                                  _vm.perfilHelpers.menuFuncionalitiesEnum
                                    .SOLICITAR_ENDOSSO,
                                ]) &&
                                _vm.$enums.PolicyStatus.Emitida ==
                                  item.PolicyStatusId &&
                                _vm.validBroker(item)
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { divider: "" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.showSolicitacaoEndosso(
                                              item.PolicyUniqueId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "text-base px-6 py-2",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Solicitar endosso "),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "corpo" }, [
                  _c("div", { staticClass: "item first" }, [
                    _c("p", [
                      _vm._v("\n            Tomador: "),
                      _c("b", [
                        _vm._v(" " + _vm._s((item || {}).PolicyHolderName)),
                      ]),
                      item.PolicyHolderName
                        ? _c(
                            "i",
                            {
                              staticClass: "copy",
                              attrs: { role: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard(
                                    item.PolicyHolderName
                                  )
                                },
                              },
                            },
                            [_c("onPointIcon", { attrs: { icon: "copy" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("p", [
                      _c("strong", { staticStyle: { color: "transparent" } }, [
                        _vm._v(" Tomador:"),
                      ]),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("maskCpfCnpj")(
                              (item || {}).PolicyHolderDocument
                            )
                          ) +
                          "\n            "
                      ),
                      _c(
                        "i",
                        {
                          staticClass: "copy",
                          attrs: { role: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.copyToClipboard(
                                item.PolicyHolderDocument
                              )
                            },
                          },
                        },
                        [_c("onPointIcon", { attrs: { icon: "copy" } })],
                        1
                      ),
                    ]),
                    _c("p", [
                      _vm._v("\n            Segurado: "),
                      _c("b", [_vm._v(" " + _vm._s((item || {}).InsuredName))]),
                      item.InsuredName
                        ? _c(
                            "i",
                            {
                              staticClass: "copy",
                              attrs: { role: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard(item.InsuredName)
                                },
                              },
                            },
                            [_c("onPointIcon", { attrs: { icon: "copy" } })],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("p", [
                      _c("strong", { staticStyle: { color: "transparent" } }, [
                        _vm._v(" Segurado:"),
                      ]),
                      _vm._v(
                        "\n\n            " +
                          _vm._s((item || {}).InsuredDocumentMask) +
                          "\n            "
                      ),
                      _c(
                        "i",
                        {
                          staticClass: "copy",
                          attrs: { role: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.copyToClipboard(item.InsuredDocument)
                            },
                          },
                        },
                        [_c("onPointIcon", { attrs: { icon: "copy" } })],
                        1
                      ),
                    ]),
                    item.BrokerName
                      ? _c("p", [
                          _vm._v("\n            Corretor:"),
                          _c("b", [
                            _vm._v(" " + _vm._s((item || {}).BrokerName)),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("p", [
                      _vm._v("\n            Modalidade:"),
                      _c("b", [_vm._v(" " + _vm._s((item || {}).Modality))]),
                    ]),
                    _c("p", [
                      _vm._v("\n            IS: "),
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("maskCurrency")(
                                (item || {}).InsuredAmountValue
                              )
                            )
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v("\n            Prêmio: "),
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("maskCurrency")((item || {}).PremiumValue)
                            )
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v("\n            Contém endosso?"),
                      _c("b", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              (item || {}).EndorsementCount > 0 ? "Sim" : "Não"
                            )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("p", [
                      _vm._v("\n            Emissão:\n            "),
                      _c("b", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              item.CreateDate
                                ? _vm.$utils.dateToDDMMYYYY(item.CreateDate)
                                : "-"
                            )
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _vm._v("\n            Vigência:\n            "),
                      _c("b", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              item.StartDate
                                ? _vm.$utils.dateToDDMMYYYY(item.StartDate)
                                : "-"
                            )
                        ),
                      ]),
                      _vm._v("\n            Até\n            "),
                      _c("b", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              item.EndDate
                                ? _vm.$utils.dateToDDMMYYYY(item.EndDate)
                                : "-"
                            )
                        ),
                      ]),
                    ]),
                    _c(
                      "p",
                      [
                        _vm._v("\n            Status:\n            "),
                        _c(
                          "vs-button",
                          {
                            staticClass: "status",
                            attrs: {
                              type: "filled",
                              size: "small",
                              color: _vm._f("setLabelStatusPolicy")(
                                item.PolicyStatusId
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getStatus(item.PolicyStatusId)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _c(
            "div",
            {
              staticClass:
                "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32",
            },
            [
              _vm._m(0),
              _c("p", [
                _vm._v("Você ainda não possui nenhuma apólice cadastrada."),
              ]),
            ]
          ),
      _c("vs-pagination", {
        attrs: { total: _vm.numberOfPages },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
      _vm.listaTiposSolicitacaoEndosso &&
      _vm.listaTiposSolicitacaoEndosso.length
        ? _c(
            "vs-popup",
            {
              attrs: { title: "Buscar apólice", active: _vm.popupEndosso },
              on: {
                "update:active": function ($event) {
                  _vm.popupEndosso = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row mt-8 mb-8 ml-3 mr-3" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("p", { staticClass: "font-bold pt-3 pb-3 w-full" }, [
                      _vm._v("Número apólice"),
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full md:w-3/5 md:pr-3",
                      attrs: {
                        size: "large",
                        "label-placeholder": "Número da apólice",
                      },
                      model: {
                        value: _vm.filter.policyNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "policyNumber", $$v)
                        },
                        expression: "filter.policyNumber",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex flex-row-reverse mt-4" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-4",
                        attrs: { color: "primary", type: "filled" },
                        on: {
                          click: function ($event) {
                            return _vm.buscarApolices(true)
                          },
                        },
                      },
                      [_vm._v("\n          Buscar\n        ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.listaTiposSolicitacaoEndosso &&
      _vm.listaTiposSolicitacaoEndosso.length
        ? _c(
            "vs-popup",
            {
              attrs: {
                title: "Solicitação de endosso",
                active: _vm.popupSolicitacaoEndosso,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupSolicitacaoEndosso = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row mt-8 mb-8 ml-3 mr-3" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("p", { staticClass: "font-bold pt-3 pb-3 w-full" }, [
                    _vm._v("Tipo de solicitação"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          name: "tipo da solicitacao",
                          rules: "required",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-select", {
                                    staticClass: "w-full",
                                    attrs: {
                                      label: "Name",
                                      appendToBody: true,
                                      options: _vm.listaTiposSolicitacaoEndosso,
                                      "show-no-options": false,
                                      "show-no-results": false,
                                      placeholder:
                                        "Selecione um tipo da solicitação do endosso",
                                    },
                                    model: {
                                      value: _vm.selectedTipoSolicitacaoEndosso,
                                      callback: function ($$v) {
                                        _vm.selectedTipoSolicitacaoEndosso = $$v
                                      },
                                      expression:
                                        "selectedTipoSolicitacaoEndosso",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]",
                                        },
                                      ],
                                      staticClass: "text-danger text-sm",
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(errors[0]) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3112400654
                        ),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "vw-row ml-3 mr-3" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full text-right" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-primary",
                        attrs: {
                          disabled: !(_vm.selectedTipoSolicitacaoEndosso || {})
                            .Id,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goToSolicitacaoEndosso()
                          },
                        },
                      },
                      [_vm._v("\n          Prosseguir\n        ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("listar-parcelas-popup", {
        attrs: {
          parcelasModal: _vm.parcelasModal,
          propostaUniqueId: _vm.policyId,
          autoLoad: false,
        },
        on: {
          fecharParcelasModal: function ($event) {
            _vm.parcelasModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { staticClass: "material-icons text-6xl" }, [
        _vm._v("description"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }